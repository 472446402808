<template>
  <div>   
      <mbs-page-tab 
          :items="PageLinks"/> 
    <v-container grid-list-xl> 
        <v-layout align-center="" justify-center="">
            <v-col sm="12" cols="12">
                <br>
                <v-card >  
                    <mbs-item-manager
                        :table_title="'Manage Errors'"
                        :table_title_icon="'warning'"
                        :top_label="false"
                        :show_expand="true"
                        :single_expand="true"
                        :row_click="false"
                        :add_vertical_lines="true"
                        :table_actions="table_actions"
                        :name_items="NAME_ITEMS"
                        :name_item="NAME_ITEM"
                        :value_items="VALUE_ITEMS"
                        :value_item="VALUE_ITEM"
                        :name_child_items="NAME_ITEMS"
                        :name_child_item="NAME_ITEM"
                        :value_child_items="VALUE_ITEMS"
                        :value_child_item="VALUE_ITEM"
                        :field_items="FIELD_ITEMS"
                        :header_items="HEADER_ITEMS" 
                        :header_child_items="CHILD_HEADER_ITEMS" 
                        :data_items="UserErrors" 
                        :data_child_items="UserErrors" 
                        :is_joined="false"   
                        :path_item="PATH_ITEM" 

                        @SELECT_ITEM_ACTION="ITEM_ACTION"
                        @SELECT_TABLE_ACTION="TABLE_ACTION"
                    />
                </v-card>
            </v-col>  
        </v-layout>
    </v-container>  
  </div> 
</template>

<script>
    import DATA from '../../../plugins/DATA'
    let PAGE_NAME = DATA.ITEMS.ERRORS.values+"_PAGE" 
    import {mapState,mapGetters } from "vuex" 
import errors from '../../../store/errors'
    export default {
        name:PAGE_NAME, 
        data() {
            return {
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10, 
                CURRENT_TAP:0,

                NAME_ITEMS:DATA.ITEMS.ERRORS.names,
                NAME_ITEM:DATA.ITEMS.ERRORS.name,
                VALUE_ITEMS:DATA.ITEMS.ERRORS.values,
                VALUE_ITEM:DATA.ITEMS.ERRORS.value, 

                FIELD_ITEMS:[ ], 
                items : [ ], 
                table_actions:[ 
                    // {btn:true,text:"Add Bus Trip",action:"add_item",icon:"mdi-bus"},
                    // {type:"action",text:"Export Json",action:"export_items",icon:"mdi-download"},  
                    {type:"action",text:"Refresh",action:"refresh",icon:"mdi-refresh"},  
                    {type:"action",icon:"mdi-microsoft-excel",action:"export_excel",color:"secondary", outlined:false,  text:"Export to Excel"},
                    {type:"action",text:"Delete All Errors",action:"delete_all_items",icon:"mdi-delete"},  
                ]
            }
        },
        created() {
            try { 
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try {
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar', 
                    images:this.items,
                    color:'black',
                    height:"300",
                    opacity:'0.1'
                })
                this.FEEDBACKS(1000) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            }
            
        },
        computed: {
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            },
            vs_width(){
                let vs = this.vs
                if(!vs){return null}
                return vs.width
            },  
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({
                // Users: state=> state.users[(DATA.ITEMS.USERS.values).toUpperCase()], 
                
                StockOutlets: state=> state.items[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()],
                JoinedStockOutlets: state=> state.join[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()], 
            }),
            users(){
                let items  =this.$store.getters.getUsers
                let list = items?[...items]:null
                if(!list){return null}
                list.sort(this.MBS.actions.dynamicSort('created_at',false))
                return list
            }, 
            JoinedUsers(){
                let users = this.users
                console.log(users,'users....');
                let join = this.MBS.actions.JOIN_USERS(users)
                return join
            }, 
            
            PATH_ITEM(){
                let SERVER_NAME = DATA.APP.SERVER
                return '/'+this.MBS.actions.TEXT_UP(this.NAME_ITEMS+'/') 
            }, 
            HEADER_ITEMS(){
                return [ 
                    {id:1,name:"General",title:true}, 
                    {id:0,name:"Number",value:'no',show:false},   
                    {id:0,name:"image",show:false}, 
                    {id:0,name:"date",value:"date_",show:true}, 
                    {id:0,name:"Name",value:"user_name",show:true},   
                    {id:0,name:"Surname",value:"user.surname",show:false},      
                    {id:0,name:"user",value:"user.names",show:false},    
                    {id:0,name:"phone",value:'user.phone_number',show:true}, 
                    {id:0,name:"User Group",value:'company_user_group_name',show:false},       
                    {id:0,name:"Company",value:'user_status.company_key',show:true},   
                    {id:0,name:"on",value:'user_status.online',show:true},   
                    {id:0,name:"logged",value:'user_status.logged',show:true},   
                    {id:0,name:"status",value:'user_status.active_status',show:false},    
                    {id:0,name:"Errors",value:"errors_size",show:true},  
                    {id:0,name:"action",align:"right",show:true,item:[
                        {name:"Log "+this.NAME_ITEM,action:"log_error",icon:"mdi-bug"}, 
                        {name:"Delete User Error",action:"delete_user_errors",icon:"mdi-delete"}, 
                    ]}, 
                ]
            },
            CHILD_HEADER_ITEMS(){
                return [ 
                    {id:1,name:"General",title:true}, 
                    {id:0,name:"Number",value:'no',show:false},  
                    {id:0,name:"Device",value:'icon',show:true},  
                    {id:0,name:"browser",value:'browser.name',show:false},  
                    {id:0,name:"os",value:'browser.os',show:false},  
                    {id:0,name:"B version",value:'browser.version',show:false},  
                    {id:0,name:"from",show:true},   
                    {id:0,name:"page",value:"page",show:true},   
                    {id:0,name:"A Version",value:"app_version",show:true},   
                    {id:0,name:"Name",value:"user_name",show:true},   
                    {id:0,name:"Surname",value:"user.surname",show:false},      
                    {id:0,name:"user",value:"user.names",show:false},    
                    {id:0,name:"phone",value:'user.phone_number',show:false},   
                    {id:0,name:"date",value:"date_",show:true}, 
                    {id:0,name:"error",value:"error",show:false}, 
                    {id:0,name:"action",align:"right",show:true,item:[
                        {name:"Log "+this.NAME_ITEM,action:"log_error",icon:"mdi-bug"}, 
                        {name:"Delete Child Error",action:"delete_child_errors",icon:"mdi-delete"}, 
                    ]}, 
                ]
            }, 
            AllErrors(){
                let items = this.$store.getters.getAppErrors 
                if(!items){return null} 
                let list = [...items] 
                list.sort(this.MBS.actions.dynamicSort("date",false)) 
                return list
            },   
            UserErrors(){
                let all_errors = this.AllErrors
                let users = this.JoinedUsers
                if(!all_errors){return null}
                let items=[]
                all_errors.forEach(error_item => {
                    let key = error_item.key
                    let item = {...error_item}
                    delete item.key
                    delete item.i
                    let errors = this.MBS.actions.OBJECT_ITEMS(item)
                    let errors_size = this.MBS.actions.SIZE(errors)
                    let user = users?users.find(u=>u.uid==key||u.key==key):null
                    let user_name = user?.name
                    let image = user?.image
                    let user_status = user?.user_status

                    let last_error = null
                    if (errors) { 
                        errors.sort(this.MBS.actions.dynamicSort('date',false))
                        last_error = errors[0]
                    }

                    items.push({
                        child_data:errors,
                        errors:errors,
                        errors_size:errors_size,
                        key:key,
                        uid:key,
                        user:user,
                        user_name:user_name,
                        image:image,
                        user_status:user_status,
                        last_error:last_error,
                        date:last_error?last_error.date:null,
                    })
                });
                items.sort(this.MBS.actions.dynamicSort('date',false))
                console.log(all_errors,'all_errors.....................');
                console.log(items,'UserErrors.....................');
                return items
            },
            PageLinks(){  
                let thisLinks = this.MBS.actions.FIND_SUB_LINKS("cpanel",1) 
                return thisLinks
            }, 
        },
        methods: {  
            ITEM_ACTION(action,item){
                try {
                    console.log(item,'action');
                    if(action=="delete_user_errors"){
                        let mbs_text = this.MBS.text.item_action("User Errors","Delete")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:PAGE_NAME+"=DELETE-USER-ERRORS", 
                                data:item,
                                path:DATA.PATHS.errors+item.key
                            } 
                        })
                    }else if(action=="delete_child_errors"){
                        let mbs_text = this.MBS.text.item_action("Error","Delete")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:PAGE_NAME+"=DELETE-USER-ERRORS", 
                                data:item,
                                path:DATA.PATHS.errors+item.key+"/"+item
                            } 
                        })
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ITEM_ACTION',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            TABLE_ACTION(action){
                try {
                    if (action == 'refresh') {
                        this.$store.dispatch('fi_get_item',{
                          name:DATA.ITEMS.ERRORS.values,
                          on:false, 
                          cash:true, 
                          get:true
                        }).then(res=>{
                            console.log(res,'res..........');
                        }).catch(error=>{
                            console.log(error,'error..........');
                        })
                    }
                     console.log(action,'action111');
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            //---------------[MAIN FUNCTIONS]------------ 
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=DELETE-USER-ERRORS') {
                            if (action.YES) {
                                this.loading = true  
                                this.$store.dispatch("fi_delete",{ 
                                    action:this.VALUE_ITEM,
                                    path:action.path, 
                                    us:this.us
                                }).then(res=>{
                                    this.loading = false   
                                    console.log(res,'res....');
                                    let mbs_text = this.MBS.text.item_action_success(this.NAME_ITEM,"deleting")
                                    this.MBS.actions.dialog({
                                        show:true,
                                        fixed:true,
                                        title:mbs_text.title,
                                        text:mbs_text.text,
                                        btnYes:mbs_text.btnYes,  
                                    }) 
                                }).catch(error=>{
                                    console.log(error,'error.....');
                                    this.loading = false  
                                    let mbs_text = this.MBS.text.item_action_fail(this.NAME_ITEM,"deleting")
                                    this.MBS.actions.dialog({
                                        show:true,
                                        fixed:true,
                                        title:mbs_text.title,
                                        text:mbs_text.text,
                                        btnYes:mbs_text.btnYes,  
                                    }) 
                                })  
                            } else {
                                
                            }
                        }  
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
            FEEDBACKS(time){ 
                try {
                    if (!time) { time=400 }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses

                        //Check process 
                        if (ps) {
                            let gettingItems = ps.find(process=>{
                                return process.name == 'getting/'+this.VALUE_ITEMS
                            })
                            
                            let addingItem = ps.find(process=>{
                                return process.name == 'adding/'+this.VALUE_ITEM
                            })
                            let updatingItem = ps.find(process=>{
                                return process.name == 'updating/'+this.VALUE_ITEM
                            })  
                            let deletingItem = ps.find(process=>{
                                return process.name == 'deleting/'+this.VALUE_ITEM
                            })
                            let deletingAllItems = ps.find(process=>{
                                return process.name == 'deleting/'+this.VALUE_ITEMS
                            })
                             

                            if (gettingItems) {
                                this.PAGE_LOAD =false
                            }else{
                                this.PAGE_LOAD =true
                            }

                           

                            if (addingItem) {
                                this.MBS.actions.progressDialog({
                                    show:true,
                                    fixed:false,
                                    title:"Updating"
                                }) 
                                this.inputLoading = true
                            }else{ 
                            }  

                            if (updatingItem) {
                                this.MBS.actions.progressDialog({
                                    show:true,
                                    fixed:false,
                                    title:"Updating"
                                }) 
                                this.inputLoading = true
                            }else{ 
                            }  

                            if (deletingItem) {
                               this.MBS.actions.progressDialog({
                                    show:true, 
                                    fixed:false,
                                    title:"Deleting..."
                                }) 
                            }else{ 
                            }  

                            if (deletingAllItems) {
                               this.MBS.actions.progressDialog({
                                    show:true, 
                                    fixed:true,
                                    title:"Deleting all..."
                                }) 
                            }else{ 
                            }

                             
                        }else{
                            this.PAGE_LOAD =true
                        }

                        //ADD ITEM RESPONSE 
                        
                    },time)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    })  
                } 
            },
            PAGE_PERMISSION(){
                try {
                    let us
                    if (!us) {
                        this.MBS.actions.go("/")
                    }  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'PAGE_PERMISSION',
                        page:PAGE_NAME, 
                    })
                }
            },
    
        },
        watch: { 
            us(v){
                this.PAGE_PERMISSION()
            },
            loading(value){
                this.FEEDBACKS(1)
            },
            processes(value){
                this.FEEDBACKS(1)
            },
            responses(value){
                this.FEEDBACKS(1) 
            } 
        },
  }
</script>